.page {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    &__wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
    }

    &__header {
        position: relative;

        &::before {
            content: "";
            position: fixed;
            z-index: $z-index__header;
            top: 0; right: -32px; left: -32px;
            height: $header__height--mobile;
            background-color: $header__background-color;
            box-shadow: $header__box-shadow;

            @include media-breakpoint-up(lg) {
                height: $header__height--unpinned;
            }

            @include media-breakpoint-up(xxl) {
                height: $header__height;
                transition: height .3s ease-in-out;
            }

            html.headroom--unpinned & {
                @include media-breakpoint-up(xxl) {
                    height: $header__height--unpinned;
                }
            }
        }

        @include container-child-combinator {
            &:first-of-type {
                position: fixed;
                z-index: $z-index__header + 1;
                top: 0; right: 0; left: 0;
                display: flex;
                flex-direction: row;
                gap: 0 24px;
                @if $main-navigation__mode == off-canvas {
                    padding-right: $header__height--mobile + 16px;
                }
                height: $header__height--mobile;

                @if $main-navigation__mode == menu {
                    @media (max-width: #{$off-canvas__breakpoint - .02}) {
                        padding-right: $header__height--mobile + 16px;
                    }

                    @media (min-width: #{$off-canvas__breakpoint}) {
                        > * {
                            &:not(.menu-navigation) {
                                height: 55%;
                            }

                            &.menu-navigation {
                                height: 45%;
                            }
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    @if $main-navigation__mode == off-canvas {
                        padding-right: $header__height--unpinned + 64px;
                    }
                    height: $header__height--unpinned;
                }

                @include media-breakpoint-up(xxl) {
                    @if $main-navigation__mode == off-canvas {
                        padding-right: $header__height + 64px;
                    }
                    height: $header__height;
                    transition: padding-right .3s ease-in-out, height .3s ease-in-out;
                }

                html.headroom--unpinned & {
                    @include media-breakpoint-up(xxl) {
                        @if $main-navigation__mode == off-canvas {
                            padding-right: $header__height--unpinned + 64px;
                        }
                        height: $header__height--unpinned;
                    }
                }
            }
        }
    }

    &__main {
        flex-shrink: 0;

        // position: relative;
        // padding-top: 64px;
        // scroll-margin-top: $header__height--mobile + 64px;

        // @include media-breakpoint-up(lg) {
        //     scroll-margin-top: $header__height--unpinned + 64px;
        // }

        // @include media-breakpoint-up(xxl) {
        //     scroll-margin-top: $header__height + 64px;
        // }

        // &.page__main--padding-top {
        //     padding-top: $header__height--mobile + 64px;

        //     @include media-breakpoint-up(lg) {
        //         padding-top: $header__height--unpinned + 64px;
        //     }

        //     @include media-breakpoint-up(xxl) {
        //         padding-top: $header__height + 64px;
        //     }
        // }

        body:not([data-template='home']):not(.has-banner-image):not(.has-banner-video):not(.has-breaking-ribbon) & {
            padding-top: $header__height--mobile;

            @include media-breakpoint-up(lg) {
                padding-top: $header__height--unpinned;
            }

            @include media-breakpoint-up(xxl) {
                padding-top: $header__height;
            }
        }

        body:not([data-template='home']):not(.has-banner-image):not(.has-banner-video).has-breaking-ribbon & {
            padding-top: $header__height--mobile + 64px;

            @include media-breakpoint-up(lg) {
                padding-top: $header__height--unpinned + 64px;
            }

            @include media-breakpoint-up(xxl) {
                padding-top: $header__height + 64px;
            }
        }
    }

    &__footer {
        margin-top: auto;

        .d-flex {
            &.flex-column {
                gap: 48px 0;
            }
        }

        .row {
            gap: 48px 0;

            &.row--first {
                margin-bottom: 64px;

                .row {
                    font-size: 22px;
                    line-height: 2;
                    letter-spacing: 0.1em;

                    a {
                        &[href*='mailto:'],
                        &[href*='tel:'] {
                            display: inline;
                            padding-bottom: .125em;
                            text-decoration: none;
                            background: {
                                image: linear-gradient(to right, $color__white 50%, rgba($color__white, .5) 50%);
                                repeat: repeat-x;
                                position: 100% calc(100% - .075em);
                                size: 200% .075em;
                            }
                            transition: background-position .3s ease-in-out;

                            &:hover,
                            &:focus {
                                background-position: 0 calc(100% - .075em);
                            }
                        }
                    }
                }
            }

            &.row--last {
                margin-top: 64px;
                padding-top: 32px;
                background-image: linear-gradient(to right, transparent #{$grid-gutter-width * .5}, rgba($color__white, .5) #{$grid-gutter-width * .5}, rgba($color__white, .5) calc(100% - #{$grid-gutter-width * .5}), transparent calc(100% - #{$grid-gutter-width * .5}));
                background-repeat: no-repeat;
                background-size: 100% 1px;
            }
        }

        // .logo {
        //     @media (min-width: #{map-get-last-value($container-max-widths)}) {
        //         margin-left: calc((100vw - #{map-get-last-value($container-max-widths)}) * .5);
        //         margin-left: calc(((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5);
        //     }
        // }

        .top-link {
            // margin-left: 48px;
            margin-left: auto;
            width: 44px;
            height: 44px;

            @include media-breakpoint-up(lg) {
                width: 48px;
                height: 48px;
            }

            &.top-link--sticky {
                button {
                    position: fixed;
                    z-index: $z-index__header - 2;
                    right: $grid-gutter-width * .5;
                    bottom: 16px;

                    @each $breakpoint, $container-padding-size in $container-padding-sizes {
                        @include media-breakpoint-up(#{$breakpoint}) {
                            right: $container-padding-size;
                        }
                    }
                }
            }

            &:not(.top-link--sticky) {
                button {
                    @media (min-width: #{map-get-last-value($container-max-widths)}) {
                        transform: translateX(calc((100vw - #{map-get-last-value($container-max-widths)}) / 2));
                        transform: translateX(calc(((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) / 2));
                    }
                }
            }

            &.top-link--hide {
                button {
                    opacity: 0;
                }
            }

            button {
                transition: background-color .15s linear, opacity .15s linear;
            }
        }
    }
}
