@media (min-width: 62rem) and (min-width: 87.5rem){
    .off-canvas__trigger:checked+.off-canvas__wrapper .off-canvas__inner{
        min-height: 880px;
    }
}

@media (min-width: 62rem) and (min-width: 62rem){
    .off-canvas__trigger:checked+.off-canvas__wrapper .off-canvas__inner{
        min-height: 900px;
    }
}
